// Votes.js
import React, { useContext, useEffect, useState } from 'react';
import { ZipCodeContext } from '../../contexts/ZipCodeContext';
import BillCard from '../../components/BillCard/BillCard';
import './Votes.css';

function Votes() {
  const { zipCode } = useContext(ZipCodeContext);
  const [bills, setBills] = useState([]);

  useEffect(() => {
    if (zipCode) {
      fetchBills(zipCode);
    }
  }, [zipCode]);

  const fetchBills = async (zip) => {
    try {
      const response = await fetch(`/api/bills?zipCode=${zip}`);
      const data = await response.json();
      data.sort((a, b) => new Date(b.voteDate) - new Date(a.voteDate));
      setBills(data);
    } catch (error) {
      console.error('Error fetching bills:', error);
    }
  };

  if (!zipCode) {
    return <p>Please enter your zip code on the Home page.</p>;
  }

  return (
    <div className="votes">
      {bills.map((bill) => (
        <BillCard key={bill.id} bill={bill} />
      ))}
    </div>
  );
}

export default Votes;