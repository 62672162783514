// src/components/BillCard/BillCard.js
import React from 'react';
import './BillCard.css';

function BillCard({ bill }) {
  const { level, title, summary } = bill;

  const levelColors = {
    federal: 'var(--federal-color)',
    state: 'var(--state-color)',
    local: 'var(--local-color)',
  };

  const cardStyle = {
    borderLeftColor: levelColors[level] || 'var(--default-color)',
  };

  const badgeStyle = {
    backgroundColor: levelColors[level] || 'var(--default-color)',
  };

  return (
    <div className="bill-card" style={cardStyle}>
      <div className="bill-card-header">
        <span className="bill-level" style={badgeStyle}>
          {level.toUpperCase()}
        </span>
        <h2 className="bill-title">{title}</h2>
      </div>
      <p className="bill-summary">{summary}</p>
    </div>
  );
}

export default BillCard;
